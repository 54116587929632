<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("registerOfDirectTrades") }}
				</AppText>
			</div>
		</div>

		<div class="section__body">
			<div class="table-block" :class="isMobileMedium ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th class="text-bold">{{ $t("ordinalNumber") }}</th>
							<th class="text-bold">{{ $t("numberOffer") }}</th>
							<th class="text-bold">{{ $t("organizationNameCustom") }}</th>
							<th class="text-bold">{{ $t("orderingTin") }}</th>
							<th class="text-bold">{{ $t("companyNameSupplier") }}</th>
							<th class="text-bold">{{ $t("suplierTin") }}</th>
							<th class="text-bold">{{ $t("productName") }}</th>
							<th class="text-bold">{{ $t("count") }}</th>
							<th class="text-bold">{{ $t("startDate") }}</th>
							<th class="text-bold">{{ $t("expirationDate") }}</th>
							<th class="text-bold">{{ $t("addComment") }}</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="list && Array.isArray(list) && list.length">
							<tr class="text-center" v-for="(item, id) in list" :key="id">
								<td>{{ query.filters.skip + id + 1 }}</td>
								<td>{{ item.offerNumber || "-" }}</td>
								<td>{{ item.customerCompanyName | translate }}</td>
								<td>{{ item.customerTin || "-" }}</td>
								<td>{{ item.providerName | translate }}</td>
								<td>{{ item.providerTin || "-" }}</td>
								<td>{{ item.productName || "-" }}</td>
								<td>{{ item.quantity || "-" }}</td>
								<td width="180">
									{{ item.beginDate ? $moment(item.beginDate).format("DD.MM.YYYY HH:mm:ss") : "-" }}
								</td>
								<td width="180">
									{{ item.endDate ? $moment(item.endDate).format("DD.MM.YYYY HH:mm:ss") : "-" }}
								</td>
								<td width="180">
									<app-button
										theme="linear"
										height="40"
										sides="20"
										font-size="8"
										@click="openModal(item.lotId)"
									>
										{{ $t("add") }}
									</app-button>
									<span class="d-flex justify-content-center align-items-center mt-10">
										<img src="@/assets/icons/comment.svg" alt="comment-icon" />
										{{ item.commentCount }}
									</span>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr class="text-center">
								<td colspan="10">{{ $t("noData") }}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>

			<pagination
				:total="this.total"
				:perPage="query.filters.take"
				@paginate="pagination($event)"
				class="paginationClassForActive"
			/>
		</div>
		<AppModal v-if="showModal" v-model="showModal" :closeButton="false" class="comment-modal">
			<div class="modal-hold-block">
				<div class="modal__holder">
					<div class="modal-body flex-1">
						<div class="mb-30 general-info">
							<table>
								<tbody>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("lotNumber") }}:</app-text>
										</td>
										<td>
											<span>{{ selectedItem?.lotNumber }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("numberOffer") }}:</app-text>
										</td>
										<td>
											<span>{{ selectedItem?.offerNumber }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("productName") }}:</app-text>
										</td>
										<td>
											<span>{{ selectedItem?.productName }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("count") }}:</app-text>
										</td>
										<td>
											<span>{{ selectedItem?.quantity }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("unitMeasurement") }}:</app-text>
										</td>
										<td>
											<span>{{ selectedItem?.measure }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("contractAmount") }}:</app-text>
										</td>
										<td>
											<span>{{ selectedItem?.contractSum }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("startDate") }}:</app-text>
										</td>
										<td>
											<span>{{
												selectedItem?.beginDate
													? $moment(selectedItem?.beginDate).format("DD.MM.YYYY HH:mm:ss")
													: "-"
											}}</span>
										</td>
									</tr>
									<tr>
										<td>
											<app-text size="16" weight="600">{{ $t("expirationDate") }}:</app-text>
										</td>
										<td>
											<span>{{
												selectedItem?.endDate
													? $moment(selectedItem?.endDate).format("DD.MM.YYYY HH:mm:ss")
													: "-"
											}}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="table-block modal-table">
							<table>
								<thead>
									<tr>
										<th class="text-bold">{{ $t("ordinalNumber") }}</th>
										<th class="text-bold">{{ $t("companyComment") }}</th>
										<th class="text-bold">{{ $t("fullName") }}</th>
										<th class="text-bold">{{ $t("companyCommentTin") }}</th>
										<th class="text-bold">{{ $t("date") }}</th>
										<th class="text-bold">{{ $t("comments") }}</th>
									</tr>
								</thead>
								<tbody>
									<template
										v-if="
											selectedItem && selectedItem.detailsList && selectedItem.detailsList.length
										"
									>
										<tr
											class="text-center"
											v-for="(selItem, idx) in selectedItem.detailsList"
											:key="idx"
										>
											<td>{{ oneItem.skip + idx + 1 }}</td>
											<td>{{ selItem.companyName | translate }}</td>
											<td>{{ fullName(selItem.fullName) }}</td>
											<td>{{ selItem.tin || "-" }}</td>
											<td width="180">
												{{
													selItem.createdDate
														? $moment(selItem.createdDate).format("DD.MM.YYYY HH:mm:ss")
														: "-"
												}}
											</td>
											<td>
												<app-text size="16" weight="600">{{ selItem.comment || "-" }}</app-text>
											</td>
										</tr>
									</template>
									<template v-else>
										<tr class="text-center">
											<td colspan="11">{{ $t("noData") }}</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
						<pagination
							:total="oneItem.total"
							:perPage="oneItem.take"
							@paginate="paginationOneItem($event)"
							class="paginationClassForActive"
						/>

						<div class="mb-30 warning-message">
							<app-text
								size="18"
								weight="700"
								class="text-danger d-flex justify-content-center align-items-center"
							>
								<span class="mr-10">
									{{ $t("warning") }}
								</span>
								<img src="@/assets/icons/waning.svg" alt="waning-icon" style="width: 24px" />
							</app-text>
							<p class="text-danger font-weight-bold">{{ $t("commentRequirements") }}</p>
						</div>

						<div class="">
							<label class="label-input-text technical-params">
								{{ $t("comment") }}
							</label>
							<div>
								<textarea
									class="base-textarea"
									cols="30"
									rows="10"
									:disabled="isLoggedIn"
									v-model="comment"
									:class="{ 'is-invalid text-danger': isLoggedIn }"
									:placeholder="isLoggedIn ? $t('pleaseRegister') : ''"
								></textarea>
							</div>
						</div>
					</div>
					<div class="modal-footer mt-30">
						<div style="display: flex; justify-content: flex-end; gap: 10px">
							<app-button
								@click="closeModal"
								theme="linear"
								height="40"
								sides="20"
								font-size="14"
								class="font_Msemibold"
								>{{ $t("close") }}
							</app-button>
							<app-button
								@click="sendComment"
								theme="main"
								sides="16"
								height="40"
								font-size="14"
								:disabled="isLoggedIn"
							>
								{{ $t("send") }}
							</app-button>
						</div>
					</div>
				</div>
			</div>
		</AppModal>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppInput from "../../components/shared-components/AppInput";
import AppButton from "../../components/shared-components/AppButton";
import Pagination from "../../components/shared-components/Pagination.vue";
import AppModal from "@/components/shared-components/AppModal";

export default {
	name: "index",
	components: { Pagination, AppButton, AppInput, AppText, AppModal },
	data() {
		return {
			query: {
				filters: {
					skip: 0,
					take: 10,
				},
			},
			oneItem: {
				lotId: null,
				skip: 0,
				take: 10,
				total: 0,
			},
			total: 0,
			list: [],
			showModal: false,
			selectedItem: null,
			comment: null,
			offerData: {},
		};
	},
	async created() {
		await this.load();
	},
	computed: {
		isLoggedIn() {
			return !this.$store.state.userInfo?.tin;
		},
	},
	methods: {
		async pagination(val) {
			this.query.filters.skip = val - this.query.filters.take;
			await this.load();
		},
		async paginationOneItem(val) {
			this.oneItem.skip = val - this.oneItem.take;
			await this.fetchOne();
		},
		async load(page = this.query.filters.skip, take = this.query.filters.take) {
			try {
				this.$store.commit("setLoadingStatus", true);
				let params = {
					skip: page,
					take: take,
				};
				const response = await this.$api.get("/api-shop/LotRequest/GetLotsInTradeForDirectory", {
					params,
				});
				this.list = response.data.result.result;
				this.total = response.data.result.count;
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		async fetchOne(lotId = this.oneItem.lotId, skip = this.oneItem.skip, take = this.oneItem.take) {
			try {
				this.$store.commit("setLoadingStatus", true);
				console.log({ lotId, skip, take });

				const response = await this.$api.get("/api-shop/LotRequest/GetLotDetailsByIdInTradeForDirectory", {
					params: { lotId, skip, take },
				});
				this.oneItem.total = response.data.result.count;
				this.selectedItem = response.data.result.directoryLot;
			} catch (error) {
				console.log(error);
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		openModal(lotId) {
			this.showModal = true;
			this.oneItem.lotId = lotId;
			this.fetchOne(this.lotId);
		},
		closeModal() {
			this.showModal = false;
			this.selectedItem = null;
			this.oneItem = {
				lotId: null,
				skip: 0,
				take: 10,
			};
		},
		async sendComment() {
			try {
				this.$store.commit("setLoadingStatus", true);
				const signHash = await this.$eimzo.signWithKey();
				const response = await this.$api.post("/api-shop/LotRequest/AddCommentDirectoryLot", {
					lotId: this.oneItem.lotId,
					comment: this.comment,
					signHash,
				});
				this.$notification.success(this.$t("commentAdded"));
				if (response.data.result.success) {
					this.comment = null;
					this.fetchOne(this.selectedItem[0]?.lotId);
				}
			} catch (error) {
				this.$notification.error(this.$t("errorOccurred"));
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		fullName({ firstName, lastName, middleName }) {
			if (firstName && lastName && middleName) {
				return `${lastName} ${firstName} ${middleName}`;
			} else if (firstName && lastName) {
				return `${lastName} ${firstName}`;
			} else if (firstName && middleName) {
				return `${firstName} ${middleName}`;
			} else if (firstName) {
				return firstName;
			} else {
				return "-";
			}
		},
	},
};
</script>

<style lang="scss">
.paginationClassForActive {
	display: flex;
	margin-bottom: 20px;
	justify-content: center;
	width: 100%;
	.pagination {
		display: flex !important;
	}
}
.comment-modal {
	.modal__content {
		z-index: 9999 !important;
	}
	textarea {
		width: 100%;
		outline: none;
		resize: none;
		border: 1px solid #e4e4e4;
		padding: 10px;
		border-radius: 5px;
	}
	textarea:focus {
		border: 1px solid #007bff;
	}
	.modal-table {
		overflow-x: auto;
	}
}

.info {
	display: flex;
	gap: 10px;
	align-items: center;
}

.text-danger {
	color: red;
}
.align-items-center {
	align-items: center;
}

.warning-message {
	background-color: #f8d7da;
	padding: 10px;
	border-radius: 5px;
}

.general-info {
	position: sticky;
	top: 0;
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	z-index: 1;
	table {
		width: 100%;
		border-collapse: collapse;
	}
	td {
		padding: 8px;
		border: 1px solid #ddd;
	}
}
</style>
